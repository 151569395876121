var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":_vm.customCols}},[_c('associatedOption',_vm._g({attrs:{"type":"combobox","fieldAttributes":{  name: _vm.fieldAttributes.name,
                             associatedOption: _vm.reqAttributes,
                             sort:false,
                             ignoreEmptyAdd: true,
                             showValueInText:false
                           },"value":_vm.localValue,"templateContent":_vm.result},on:{"input":function($event){return _vm.checkAttribute(...arguments)}}},_vm.$listeners))],1),_c('v-col',{staticClass:"ml-3"},[(_vm.isTunnelType)?_c('associatedOption',{attrs:{"fieldAttributes":{
        name: 'tag',
        options: _vm.tunnelTag(_vm.fieldAttributes.fieldAttrInput.additionalTunnelTag),
        setEmpty: false,
        sort: false,
        ignoreEmptyAdd: true,
      },"type":"combobox","fieldAttrInput":{ class: ' ' },"value":_vm.valueTag,"templateContent":_vm.result},on:{"input":function($event){return _vm.updateField('tag', ...arguments)}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }